import axios from 'axios';
import { graphql,useStaticQuery } from 'gatsby';
import React, { createContext, useEffect, useState } from 'react';

const initialState = {};
const SearchContext = createContext(initialState);

const SearchProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [searchIndex, setSearchIndex] = useState(null);
  const [searchStore, setSearchStore] = useState(null);

  const data = useStaticQuery(graphql`
    query PreloadQuery {
      localSearchPages {
        publicIndexURL
        publicStoreURL
      }
    }
  `);

  useEffect(() => {
    const fetchData = async () => {
      const indexResponse = await axios.get(
        data.localSearchPages.publicIndexURL,
        {
          responseType: 'text',
          transformResponse: [data => data],
        }
      );
      const storeResponse = await axios.get(
        data.localSearchPages.publicStoreURL,
        {
          responseType: 'json',
        }
      );

      setSearchIndex(indexResponse.data);
      setSearchStore(storeResponse.data);

      setLoading(false);
    };

    fetchData();
  }, [
    data.localSearchPages.publicIndexURL,
    data.localSearchPages.publicStoreURL,
  ]);

  return (
    <SearchContext.Provider value={{ isLoading, searchIndex, searchStore }}>
      {children}
    </SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider };
